import React from 'react';
import { Link } from 'react-router-dom';

const MenuList = ({hideMenu}) => {
  const menuItems = [
    { id: 0, name: 'Home', link: '../' },
    { id: 1, name: 'About Us', link: '../about' },
    { id: 2, name: 'Services', link: '../services' },
    { id: 3, name: 'Portfolio', link: '../portfolio' },
    // { id: 4, name: 'Blog', link: '/blog' },
    { id: 5, name: 'Contact', link: '../contact' },
  ];

  return (
    <div className="menulist text-xl text-right font-bold">
      {menuItems.map((item) => (
        <div key={item.id} onClick={()=> {hideMenu()}}>
          <Link
            className="px-10 py-1 block rounded-sm bg-white/ hover:bg-black/10"
            to={item.link}
          >
            {item.name}
          </Link>
        </div>
      ))}
    </div>
  );
};
export default MenuList;
