import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

// Component Page
import Layout from './Pages/layout';
import Home from './Pages/Home';
import Service from './Pages/Service/';
import ServiceDetail from './Pages/ServiceDetail';
import Portfolio from './Pages/Portfolio';
import About from './Pages/About';
import Contact from './Pages/Contact';
import PortfolioDetail from './Pages/PortfolioDetail';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';

// Hook
import ScrollToTop from './Utils/GlobalHelpers';
import './i18n';
import './fonts/Outfit.ttf';

// Helmet Provider
import { HelmetProvider } from 'react-helmet-async';

// Google Analytics
import { initGA, logPageView } from './analytics';

import './index_responsive.css';
import './index.css';

initGA();

const Root = () => {
  useEffect(() => {
    logPageView();
  }, []);

  return <Router>
      <HelmetProvider>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/services">
            <Route index element={<Service />} />
            <Route path=":serviceId" element={<ServiceDetail />} />
          </Route>

          <Route path="/portfolio">
            <Route index element={<Portfolio />} />
            <Route path=":id" element={<PortfolioDetail />} />
          </Route>

          <Route path="/blog">
            <Route index element={<Blog />} />
            <Route path=":id" element={<BlogDetail />} />
          </Route>

          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Layout>
      </HelmetProvider>
    </Router>
};

const container = (document.getElementById('root'));
const root = createRoot(container);
root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
