import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaRegCalendarAlt } from "react-icons/fa"
import { FiChevronsRight } from "react-icons/fi"
import { BsSearch } from "react-icons/bs"
import { BiUser, BiCalendarCheck, BiFolder, BiRightArrowAlt } from 'react-icons/bi'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { CgFacebook } from 'react-icons/cg'
import { RiTwitterFill } from 'react-icons/ri'
import { BsLinkedin, BsPinterest } from 'react-icons/bs'

const BlogMenu = () => {
	var today = new Date();
	var month = today.toLocaleString('default', { month: 'long' });
	var day = today.getDate();
	var year = today.getFullYear();
	var date = day + ' ' + month + ', ' + year;

	const recentPost = [
		{
			title: "Blog About Technology Long superlong asdawdsasd",
			dateCreated: date,
			image: "/Images/About/abouthero.png",
			id: "11a2sdfe423r23cr23r"
		},
		{
			title: "Blog About Front End Development",
			dateCreated: date,
			image: "/Images/About/abouthero.png",
			id: "11a2sdfe423r23cr23r"
		},
		{
			title: "Blog About Back End Development",
			dateCreated: date,
			image: "/Images/About/abouthero.png",
			id: "11a2sdfe423r23cr23r"
		},
		{
			title: "Blog About Good Website Design",
			dateCreated: date,
			image: "/Images/About/abouthero.png",
			id: "11a2sdfe423r23cr23r"
		}
	];

	const blogCategory = [
		{
			name: "Technology Solution",
			id: "11a2sdfe423r23cr23r",
		},
		{
			name: "Front End",
			id: "11a2sdfe423r23cr23r",
		},
		{
			name: "Back End",
			id: "11a2sdfe423r23cr23r",
		},
		{
			name: "Website Design",
			id: "11a2sdfe423r23cr23r",
		}
	];

	const popularTag = [
		{
			name: "Website",
			id: "11a2sdfe423r23cr23r",
		},
		{
			name: "NodeJS",
			id: "11a2sdfe423r23cr23r",
		},
		{
			name: "ReactJS",
			id: "11a2sdfe423r23cr23r",
		},
		{
			name: "MongoDB",
			id: "11a2sdfe423r23cr23r",
		},
		{
			name: "ThreeJS",
			id: "11a2sdfe423r23cr23r",
		},
		{
			name: "Visual Studio Code",
			id: "11a2sdfe423r23cr23r",
		}
	];

	// Search
	const [search, setSearch] = useState('')

	const handleChange = (e) => {
		setSearch(e.target.value)
	}

	const handleClick = () => {
		const fetchData = async () => {
			try {
			  await axios.get(`https://dummyjson.com/posts/search?q=${search}`);
			} catch (error) {
			  console.log(error);
			}
		  };
	  
		  fetchData();
	}

	return (
		<div className="w-1/3 sdesk1:!w-full sdesk1:mt-20">
			<div className="relative border border-gray-bg-3 rounded-[20px] py-12 px-10 text-lg mb-6">
				<div>
					<input className="w-full py-3.5 px-4 rounded-[5px] overflow-hidden shadow-[0px_0px_10px_1px_rgba(0,0,0,0.3)]"onChange={handleChange} value={search} placeholder="Search..."/>

					<button className="absolute bg-purple-pri py-5 px-7 right-10 rounded-[5px] overflow-hidden text-white duration-75 ease-in hover:bg-purple-bg-1" type="button" onClick={handleClick}><BsSearch/></button>
				</div>
			</div>

			<div className="border border-gray-bg-3 rounded-[20px] py-12 px-10 flex flex-col relative text-lg mb-6">
				<div><span className="subheader text-xl font-semibold pt-0 pb-6">Recent Post</span></div>

				<div className="pt-5 flex flex-col gap-4">
					{recentPost.map((item, key) => (
						<Link
							to={item.id}
							key={item.id}
							className="flex flex-row gap-6 p-3 rounded-lg group transition duration-75 ease-in hover:bg-purple-bg-2"
						>
							<div className="block h-[61px] !w-[61px] aspect-square overflow-hidden rounded-md">
								<img src={item.image} alt={item.title} className="object-cover !w-full"/>
							</div>

							<div className="overflow-hidden truncate">
								<div className="overflow-hidden">
									<span className="block text-black text-base duration-75 truncate ease-in group-hover:text-white max-w-[250px]">
										{item.title}
									</span>
								</div>

								<div>
									<span className="flex gap-2 items-center text-lgray2 text-base group-hover:text-white">
										<FaRegCalendarAlt/>
										{item.dateCreated}
									</span>
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>

			<div className="border border-gray-bg-3 rounded-[20px] py-12 px-10 flex flex-col text-lg mb-6">
				<div>
					<span className="subheader text-xl font-semibold pt-0 pb-6">Category</span>
				</div>

				<div className="flex flex-col">
					{blogCategory.map((item, key) => (
						<Link to={"../blog/" + item.id} key={key}><span className="flex flex-row items-center gap-5 py-2 text-base text-lgray1 
						border-b border-b-gray-bg-1 transition duration-75 ease-in hover:text-black hover:border-black"><FiChevronsRight/> {item.name}</span></Link>
					))}
				</div>
			</div>

			<div className="border border-gray-bg-3 rounded-[20px] py-12 px-10 flex flex-col text-lg mb-6">
				<div><span className="subheader text-xl font-semibold pt-0 pb-6">Popular Tag</span></div>

				<div className="flex flex-wrap gap-4">
					{popularTag.map((item, key) => (
						<Link to={"../blog/" + item.id} key={key} className="border border-divide-border-gray rounded-[5px] py-1 px-4 text-base text-lgray1 transition duration-75 ease-in hover:text-black hover:border-black">
							{item.name}
						</Link>
					))}
				</div>
			</div>
		</div>
	);
}

const BlogList = () => {
	var today = new Date();
	var month = today.toLocaleString('default', { month: 'long' });
	var day = today.getDate();
	var year = today.getFullYear();
	var date = day + ' ' + month + ', ' + year;

	const blogs = [
		{
			title: "Blog About Technology",
			author: "MXI Solutions",
			category: "Technology",
			dateCreated: date,
			image: "/Images/About/abouthero.png",
			id: "11a2sdfe423r23cr23r",
			sypnosis: "The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should. The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should."
		},
		{
			title: "Blog About Front End Development",
			author: "MXI Solutions",
			category: "Programming",
			dateCreated: date,
			image: "/Images/About/abouthero.png",
			id: "11a2sdfe423r23cr23r",
			sypnosis: "The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should. The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should."
		},
		{
			title: "Blog About Back End Development",
			author: "MXI Solutions",
			category: "Programming",
			dateCreated: date,
			image: "/Images/About/abouthero.png",
			id: "11a2sdfe423r23cr23r",
			sypnosis: "The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should. The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should."
		},
		{
			title: "Blog About Good Website Design",
			author: "MXI Solutions",
			category: "Design",
			dateCreated: date,
			image: "/Images/About/abouthero.png",
			id: "11a2sdfe423r23cr23r",
			sypnosis: "The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should. The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should."
		}
	]

	// Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const recordsPerPage = 3
    const lastIndex = currentPage * recordsPerPage
    const firstIndex = lastIndex - recordsPerPage
    const records = blogs.slice(firstIndex, lastIndex)
    const npage = Math.ceil(blogs.length / recordsPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

	return (
		<div className="w-2/3 sdesk1:!w-full">
			<div className="pr-6 sdesk1:pr-0" id="blog">
				{records.map((item, key) => (
					<div className="rounded-[30px] overflow-hidden mb-6 bg-gray-bg-4">
						<div className="w-full aspect-video overflow-hidden">
							<img src={item.image} alt={item.title} className="contain-cover w-full"/>
						</div>

						<div className="py-10 px-6">
							<div className="flex flex-wrap gap-6 tab1:gap-4 sdesk1:gap-5 text-lg sdesk1:text-xl font-light tab1:!text-sm">
								<div>
									<span className="flex flex-row items-center gap-4 tab1:gap-2">
										<BiUser className="text-purple"/>
										{item.author}
									</span>
								</div>

								<div>
									<span className="flex flex-row items-center gap-4 tab1:gap-2">
										<BiCalendarCheck className="text-purple"/>
										{item.dateCreated}
									</span>
								</div>

								<div>
									<span className="flex flex-row items-center gap-4 tab1:gap-2">
										<BiFolder className="text-purple"/>
										{item.category}
									</span>
								</div>
							</div>

							<div>
								<div>
									<span className="header-black">	{item.title}
									</span>
								</div>

								<div className="max-h-[200px] overflow-hidden">
									<p className="paragraph">
										{item.sypnosis}
									</p>
								</div>

								<div>
									<p className="paragraph">[...]</p>
								</div>
							</div>
						</div>

						<div className="px-6 pb-10"><Link to="../" className="flex flex-row items-center gap-2 text-xl text-purple w-fit duration-75 ease-in hover:gap-3">Read More <BiRightArrowAlt className="text-2xl"/></Link></div>
					</div>
				))}
			</div>

			<div className="flex items-center justify-center w-full sdesk1:mt-20">
				<div className="grid grid-flow-col grid-cols-auto gap-4">
					<div>
						<Link to="#blog" className="l-clbtn-purple" onClick={prePage}><AiOutlineLeft/></Link>
					</div>

					{numbers.map((n, i) => (
						<div className="" key={i}>
							<Link to="#blog" className={n === currentPage ? "l-clbtn-purple active" : "l-clbtn-purple"} onClick={() => changeCPage(n)}>{n}</Link>
						</div>
					))}

					<div>
						<Link tp="#blog" className="l-clbtn-purple" onClick={nextPage}><AiOutlineRight/></Link>
					</div>
				</div>
			</div>
		</div>
	)

    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function changeCPage(id) {
        setCurrentPage(id)
    }

    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

const BlogContent = () => {
	var today = new Date();
	var month = today.toLocaleString('default', { month: 'long' });
	var day = today.getDate();
	var year = today.getFullYear();
	var date = day + ' ' + month + ', ' + year;

	const blogData = {
		title: 'IT Solution And Business',
		banner: '/Images/About/abouthero.png',
		body: [
			{
				type: 'text',
				content: {
					header: 'IT Solution And Business',
					paragraph: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.'
				}
			},
			{
				type: 'image',
				content: {
					image: [
						'/Images/About/abouthero.png',
						'/Images/About/abouthero.png'
					]
				}
			},
			{
				type: 'text',
				content: {
					header: 'IT Solution And Business',
					paragraph: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.'
				}
			}
		],
		dateCreated: date,
		category: "Batteries",
		author: "MXI Solutions",
		tag: ['Audio', 'Budget'],
	}

	const nextPost = {
		img: "/Images/About/abouthero.png",
		name: "Prev Blog Title",
		link: "../"
	}

	const prevPost = {
		img: "/Images/About/abouthero.png",
		name: "Next Blog Title",
		link: "../"
	}

	return (
		<div>
			<div>
				<div className="flex flex-col gap-20 tab1:gap-10">
					<div className="aspect-video overflow-hidden rounded-[30px]">
						<img src={blogData.banner} alt="banner" className="w-full object-contain"/>
					</div>

					
					<div className="flex flex-wrap gap-6 tab1:gap-4 sdesk1:gap-5 text-lg sdesk1:text-xl font-light tab1:!text-sm">
						<div>
							<span className="flex flex-row items-center gap-4 tab1:gap-2">
								<BiUser className="text-purple"/>
								{blogData.author}
							</span>
						</div>

						<div>
							<span className="flex flex-row items-center gap-4 tab1:gap-2">
								<BiCalendarCheck className="text-purple"/>
								{blogData.dateCreated}
							</span>
						</div>

						<div>
							<span className="flex flex-row items-center gap-4 tab1:gap-2">
								<BiFolder className="text-purple"/>
								{blogData.category}
							</span>
						</div>
					</div>

					{blogData.body.map((item) => (
						<div>{item.type === 'text'
						? <div>
							<div><span className="block text-5xl font-bold pb-9 tab1:text-4xl">{item.content.header}</span></div>

							<div><p className="paragraph">{item.content.paragraph}</p></div>
						</div>
						: item.type === 'image'
						? <div className="py-10 flex flex-wrap">
							{item.content.image.map((img, key) => (
								<div className="pr-6 pb-6 w-1/2 tab1:w-full tab1:pr-0">
									<div className="overflow-hidden rounded-2xl aspect-square">
										<img src={img} alt={blogData.title} className="object-contain w-full"></img>
									</div>
								</div>
							))}
						</div>
						: ''
						}</div>
					))}

					<div className="flex flex-wrap gap-6 pb-20 tab1:pb-10">
						<span className="title !text-base pr-4">Tags:</span>

						{blogData.tag.map((item) => (
							<Link to="../" className="border border-divide-border-gray rounded-[5px] py-1 px-4 text-base text-lgray1 transition duration-75 ease-in hover:text-black hover:border-black">{item}</Link>
						))}
					</div>
				</div>

				<div className="flex flex-row justify-between py-4 border-y border-divide-border-l">
					<Link to={prevPost.link} className="flex flex-row items-center gap-4">
						<div className="h-[70px] mob1:h-[50px] aspect-square overflow-hidden rounded-xl">
							<img src={prevPost.img} alt={prevPost.name} className="object-cover w-full"/>
						</div>

						<span className="title !text-base">Previous Post</span>
					</Link>

					<Link to={nextPost.link} className="flex flex-row items-center gap-4">
						<span className="title !text-base">Next Post</span>
						<div className="h-[70px] mob1:h-[50px] aspect-square overflow-hidden rounded-xl">
							<img src={nextPost.img} alt={nextPost.name} className="object-cover w-full"/>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}

const BlogAuthor = () => {
	const author = {
		name: 'MXI SOLUTIONS',
		img: '/Images/Navbar/MXI Logo Bulat.png',
		desc: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly',
		social: [{
			type: 'facebook',
			link: 'https://www.facebook.com',
		},
		{
			type: 'twitter',
			link: 'https://twitter.com'
		},
		{
			type: 'linkedin',
			link: 'https://linkedin.com'
		},
		{
			type: 'pinterest',
			link: 'https://www.pinterest.com'
		}]
	}

	return (
		<div className="bg-gray-bg-1 rounded-2xl overflow-hidden px-12 py-11 flex gap-9 tab1:flex-col tab1:px-8 tab1:px-7;">
			<div className="w-1/3 tab1:w-1/2 rounded-xl overflow-hidden bg-white aspect-square h-fit">
				<img src={author.img} alt={author.name} className="oject-contain w-full"/>
			</div>

			<div className="w-2/3 tab1:w-full">
				<div>
					<span className="subheader">{author.name}</span>
				</div>

				<div>
					<p className="paragraph">{author.desc}</p>
				</div>

				<div className="flex flex-row gap-4 mt-6">
					{author.social.map((item, key) => (
						<Link className="xs-clbtn-purple" to={item.link}>
							{item.type === 'facebook'
							? <CgFacebook/>
							: item.type === 'twitter'
							? <RiTwitterFill/>
							: item.type === 'linkedin'
							? <BsLinkedin/>
							: <BsPinterest/>}
						</Link>
					))}
				</div>
			</div>
		</div>
	);
}

const BlogComment = () => {
	return (
		<div className="grid divide-y divide-divide-border-l bg-gray-bg-1 rounded-2xl">
			<div className="px-12 py-11 tab1:px-8 tab1:py-7">
				<div><span className="title pb-4 block tab1:pb-3">Leave A Comment</span></div>

				<div><span className="description">All fields market with an arterisk (*) are required</span></div>
			</div>

			<div className="px-12 py-11 tab1:px-8 tab1:py-7">
				<form>
					<div className="w-full flex gap-5 pb-6">
						<input className="input-short" placeholder="Name*" required/>

						<input className="input-short" placeholder="Email*" required/>
					</div>

					<div>
						<input className="input-long" placeholder="Website"/>
					</div>

					<div className="py-6 h-[calc(100%-232px)] sdesk1:h-[calc(100%-232px)]">
						<textarea className="input-textarea" placeholder="Your Comment*" required></textarea>
					</div>

					<button type="submit" className="w-full bg-[#208BF2] py-5 rounded-lg text-white font-medium tracking-wider hover:bg-purple-bg-1">Post Comment</button>
				</form>
			</div>
		</div>
	);
}

export { BlogMenu, BlogList, BlogContent, BlogAuthor, BlogComment };