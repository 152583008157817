import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/All/Header'
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'

import { Project, projectsData } from '../../Components/Home/Child/projects'

import SEO from "../../Components/SEO";

import './Portfolio.css';

const Portfolio = () => {
	const [loaded, setLoaded] = useState(false);
  
	useEffect(() => {
	  const timer = setTimeout(() => setLoaded(true), 300);
	  return () => clearTimeout(timer);
	}, []);
    
    const portfolio = projectsData;

    const [isHovering, setIsHovering] = useState({});


    // defining project component states
    if(Object.keys(isHovering).length < 1){
        let projectStates = {...isHovering};
    
        for(let i=0; i < portfolio.length; i++){
            projectStates[portfolio[i].id] = false;
        }

        setIsHovering({...projectStates});
    }

    // handle mouse-in
    const handleMouseOver = (id) => {
        let copyObject = {...isHovering};
        copyObject[id] = true;
        setIsHovering({...copyObject});
    };

    // handle mouse-out
    const handleMouseOut = () => {
        let copyObject = {...isHovering};
        Object.keys(copyObject).forEach(v => copyObject[v] = false);
        setIsHovering({...copyObject});
    };

    const [currentPage, setCurrentPage] = useState(1)
    const recordsPerPage = 8
    const lastIndex = currentPage * recordsPerPage
    const firstIndex = lastIndex - recordsPerPage
    const records = portfolio.slice(firstIndex, lastIndex)
    const npage = Math.ceil(portfolio.length / recordsPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    return (
        <div className={`projects-page page-container ${loaded ? 'show' : ''}`}>
			<SEO
				title='Portfolio | MXI Solutions - Leading Web & Application Development Services'
				description='Discover MXI Solutions, your trusted partner in innovative web and application development services. We deliver in-house solutions tailored to your needs and support a wide range of programming languages and frameworks.'
				name='MXI Solutions'
				type='website'
			/>

            <Header title="Our Previous Work" name="Projects"/>

            <div className='projects-container flex justify-center pb-28 pt-12' id="project">
                <div className='flex flex-wrap max-w-[1400px] w-full justify-left mob1:px-3'>
                    {records.map((item, i) => (
                        <div className='cell-container' key={i}>
                            <Link to="../portfolio" className='w-full flex rounded-xl border overflow-hidden text-white hover:bg-purple-bg-1 transition-all duration-75 ease-in'>
                                <Project key={item.id} data={item} isHovering={isHovering} handleHover={() => handleMouseOver(item.id)} quitHover={ ()=> handleMouseOut() } />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <div className="pagination-container flex items-center justify-center w-full ">
                <div className="grid grid-flow-col grid-cols-auto gap-4">
                    <div>
                        <Link to="#project" className="l-clbtn-purple" onClick={prePage}><AiOutlineLeft/></Link>
                    </div>

                    {numbers.map((n, i) => (
                        <div className="" key={i}>
                            <Link to="#project" className={n === currentPage ? "l-clbtn-purple active" : "l-clbtn-purple"} onClick={() => changeCPage(n)}>{n}</Link>
                        </div>
                    ))}

                    <div>
                        <Link to="#project" className="l-clbtn-purple" onClick={nextPage}><AiOutlineRight/></Link>
                    </div>
                </div>
            </div>
        </div>
    );

    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function changeCPage(id) {
        setCurrentPage(id)
    }

    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default Portfolio;