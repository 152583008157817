import '../Home.css';
import brain from '../Icons/brain.json';
import money from '../Icons/revenue.json';
import productivity from '../Icons/bar-chart.json';

import Lottie from 'react-lottie';

const StrongPointContainer = () => {
    const automateLottieIcon = {
        loop: true,
        autoplay: true,
        animationData: brain,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }

    const highRevLottieIcon = {
        loop: true,
        autoplay: true,
        animationData: money,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }

    const prodServiceLottieIcon = {
        loop: true,
        autoplay: true,
        animationData: productivity,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }

    return(
        <div className='pointers-container section border-y border-[#D9D9D9]'>
            <div className='max-w-[1400px] px-8 flex justify-between flex-wrap mx-auto py-32 gap-y-24 relative'>
                <div className='pointers-wrapper flex flex-wrap justify-start items-center'>
                    <div className='circle mr-4 p-4'>
                        <Lottie options={automateLottieIcon} isClickToPauseDisabled={true} />
                    </div>
                    <div className='content'>
                        <span className='text-4xl font-bold'>AUTOMATE</span><br/>
                        <span className='text-xl uppercase'>repetitve tasks</span>
                    </div>
                </div>

                <div className='pointers-wrapper flex flex-wrap justify-center items-center'>
                    <div className='circle mr-4 p-4'>
                        <Lottie options={highRevLottieIcon} isClickToPauseDisabled={true} />
                    </div>
                    <div className='content'>
                        <span className='text-4xl font-bold'>PRIORITIZE</span><br/>
                        <span className='text-xl uppercase'>High-revenue tasks</span>
                    </div>
                </div>

                <div className='pointers-wrapper flex flex-wrap justify-end items-center'>
                    <div className='circle mr-4 p-4'>
                        <Lottie options={prodServiceLottieIcon} isClickToPauseDisabled={true} />
                    </div>
                    <div className='content'>
                        <span className='text-4xl font-bold'>IMPROVED</span><br/>
                        <span className='text-xl uppercase'>productivity</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default StrongPointContainer;