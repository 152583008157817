import { Link } from 'react-router-dom';

import { ClientMarquee } from "../../Home/Child/clients"; 
import { Icon } from '@iconify/react';
import {
    ConsultLottieIcon,
    SoftwareLottieIcon,
    integrateLottieIcon,
    webLottieIcon,
    legacyLottieIcon,
    supportLottieIcon
} from '../../Utils/lottie-icon';

const Video = "/Assets/videos/aboutus/motion-video-aboutus-480p.webm"
const Video2 = "/Assets/videos/aboutus/gradient2-480p-12s.webm"
const YellowGradient = "/Assets/videos/yellow-black-gradient-6s-480p.webm"
const RainbowGradient = "/Assets/videos/rainbow-480p.webm"
// const BlueYellow = "/Assets/videos/blue-yellow.mp4"

// const CarouselSwiperOption = {
//     slidesPerView: 4,
//     spaceBetween: 10,
//     breakpoints: {
//         0: {
//         slidesPerView: 1,
//         spaceBetween: 10,
//         },
//         689: {
//         slidesPerView: 2,
//         spaceBetween: 10,
//         },
//         989: {
//         slidesPerView: 3,
//         spaceBetween: 10,
//         },
//         1150: {
//         slidesPerView: 3,
//         spaceBetween: 32,
//         },
//     },
// };

const posts = [
    {
        id: "consultation",
        title: "Consultation",
        shortTitle: "Solution Consultation",
        img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
        icon: "",
        lottieIcon: {
            className: 'w-[90%] h-[90%] top-0 bottom-0 right-0 left-0 m-auto',
            path: ConsultLottieIcon
        },
        video: Video,
        descr: "Practical solutions for every challenges.",
        pageTitle: 'Let\'s Build Your Next Big Move Together',
        serviceList: [
            {
                name: 'Custom Development',
			    link: 'custom-dev',
            },
            {
                name: 'Legacy Product Upgrade',
			    link: 'legacy-upgrade',
            },
            {
                name: 'Web Design',
			    link: 'web-design',
            }
        ],
        content: {
            title: 'Let\'s Build Your Next Big Move Together',
            banner: '/Assets/images/banner/consultation.webp',
            body: [
                {
                    type: 'text',
                    content: {
                        header: 'Your Partner for Reliable, Future-Proof IT Solutions',
                        paragraph: 'At MXI Solutions, our IT consultation services are designed to help businesses of all sizes harness technology that truly meets their needs. From assessing your current systems to designing strategic, future-proof solutions, we focus on making your business processes smarter, faster, and more secure.'
                    },
                    headerClass: "desk1:text-center tab1:text-center",
                    classname: "mb-8 stab1:text-center smob1:!mb-12 smob1:text-justify smob1:px-4"
                },
                {
                    type: 'imageAndPoint',
                    content: {
                        header: 'Consulting Is More Than Giving Advice',
                        listIcons: <Icon className="text-[#208bf2] text-5xl smob1:!text-[2rem]" icon="ei:arrow-right" />,
                        list: [
                            '<b>Get Assessed</b>: Thorough evaluation of your current infrastructure.',
                            '<b>Tailored Solutions</b>: Strategic guidance and solutions that fit your business goals.',
                            '<b>Testing & Validation</b>: Ensure that the solution meets the your needs',
                            '<b>Ongoing Support</b>: Help your business stay agile with evolving technology for long-term success and stability'
                        ],
                        video: Video,
                        image: '/Assets/images/banner/consult_text.webp',
                        imagefit: "object-contain",
                        headerClass: "tab1:text-center",
                    },
                    headerClass: "smob1:text-center",
                    classname: "mb-16 smob1:!mb-8"
                },
                {
                    type: 'text',
                    content: {
                        header: 'Service Available',
                        paragraph: 'We\'re dedicated to putting your needs first, crafting solutions that align with your goals—not ours. We believe in building long-term partnerships, where your success is our top priority. Here are the services provided by MXI:',
                    },
                    headerClass: "stab1:text-center",
                    classname: "my-8 tab1:!mb-8 stab1:text-center smob1:px-4 smob1:!mb-4 smob1:text-justify"
                },
                {
                    type: 'swiper',
                    content: {
                        data: [{
                            title:'Custom Software',
                            img:'/Assets/images/banner/software-dev.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'App Development',
                            img:'/Assets/images/banner/app-dev.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Web Design',
                            img:'/Assets/images/banner/web-design.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'System Integrations',
                            img:'/Assets/images/banner/integrated-system.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Network Infrastructure',
                            img:'/Assets/images/banner/network-infra.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Disaster Planning & Recovery',
                            img:'/Assets/images/banner/recovery.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Legacy Product Upgrade',
                            img:'/Assets/images/banner/legacy-upgrade.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        }]
                    },
                    classname: 'mb-24 smob1:!mb-12'
                },
                {
                    type: 'text',
                    content: {
                        header: 'Honorable Clients',
                        paragraph: 'At MXI, we have experience across diverse industries, from financial services to retail. Our team\'s versatility and laser focus on delivering clients\' goals is something we\'re proud of.<br/><br/> Here is a list of clients we\'ve had the opportunity to work with.',
                    },
                    headerClass: "tab1:text-center",
                    classname: "mb-8 smob1:mt-4 smob1:mb-4 smob1:px-4 smob1:text-justify"
                },{
                    type: 'component',
                    component: <ClientMarquee/>,
                    classname: "clients-container w-full mb-24 smob1:mb-16"
                },{
                    type: 'linkparagraph',
                    content: {
                        header: 'Finally...',
                        paragraph: <p>Finding the right partner to bring your vision to life can be a daunting task. Building a solution to an IT problem goes beyond just programming and building IT infrastructure.<br/><br/>It also includes a deep understanding of client goals and pain points to create a solution that's truly right for them. Finding a partner with extensive experience is therefore very critical.<br/><br/>Click <Link style={{textDecoration:'underline', color:'#208bf2'}} to="/portfolio">here</Link> to learn more about our previous projects and ventures.</p>,
                    },
                    headerClass: "desk1:text-center smob1:text-left",
                    classname: "mb-8 smob1:px-4 smob1:!mb-12 smob1:text-justify"
                }
            ]
	    }
    },
    {
        id: "custom-dev",
        title: " Custom Software Development",
        shortTitle: "Software Development",
        img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
        icon: "",
        lottieIcon:{
            className: 'w-[80%] h-[80%] top-0 bottom-0 right-0 left-0 m-auto',
            path: SoftwareLottieIcon
        },
        video: Video2,
        descr: "Bringing ideas to life.",
        serviceList: [
            {
                name: 'System Integration',
			    link: 'sys-integration',
            },
            {
                name: 'Legacy Product Upgrade',
			    link: 'legacy-upgrade',
            },
            {
                name: 'Web Design',
			    link: 'web-design',
            },
        ],
        content: {
            title: 'Let\'s Build Your Next Big Move Together',
            banner: '/Assets/images/banner/app-development-banner.webp',
            images: ['/Assets/images/image-placeholder.png', '/Assets/images/image-placeholder.png'],
            body: [
                {
                    type: 'text',
                    content: {
                        header: 'Tailored Just For You',
                        paragraph: 'Custom software goes beyond off-the-shelf options, offering you a scalable, flexible, and precisely crafted solution that adapts to your unique business challenges as it grows. From initial concept to launch, we manage every stage of the development process with a focus on delivering results that empower your business to achieve more'
                    },
                    headerClass: "desk1:text-center tab1:text-center",
                    classname: "mb-8 smob1:!mb-12 stab1:text-center smob1:text-justify smob1:px-4"
                },
                {
                    type: 'imageAndPoint',
                    content: {
                        header: 'The Process',
                        list: [
                            '<b>Discovery & Planning</b>: Vision, goals, and challenges. We outline the software\'s requirements & scope.',
                            '<b>Design & Prototyping</b>:  We create intuitive, user-centered designs and prototypes to help you visualize with clarity.',
                            '<b>Development & Testing</b>:  We brings the software to life with rigorous testing',
                            '<b>Deployment & Support</b>:  We deploy the software to your environment and provide ongoing support'
                        ],
                        video: YellowGradient,
                        image: '/Assets/images/banner/softwaredev_text.webp',
                        imagefit: "object-contain",
                        headerClass: "tab1:text-center",
                    },
                    classname: "mb-32 stab1:mb-20 smob1:!mb-8"
                },
                {
                    type: 'text',
                    content: {
                        header: 'It\'s All About Attention to Detail',
                        paragraph: 'Building software can be a challenging task. Attention to detail and a forward-thinking mindset are crucial for developing software that not only benefits its users but is also future-proof for years to come. Hiring someone who isn\'t up to the job can be costly and frustrating.<br/><br/> At MXI Solutions, we take pride in our broad experience across various industries. Here are some of the companies we\'ve had the pleasure of working with.'
                    },
                    headerClass: "stab1:text-center",
                    classname: "mb-4 my-8 tab1:!mb-8 stab1:text-center smob1:px-4 smob1:!mb-4 smob1:text-justify"
                },
                {
                    type: 'component',
                    component: <ClientMarquee/>,
                    classname: "clients-container w-full mb-16 smob1:mb-8"
                },
                {
                    type: 'linkparagraph',
                    content: {
                        paragraph: <p>Click <Link style={{textDecoration:'underline', color:'#208bf2'}} to="../portfolio">here</Link> if you`re interested in learning more about our previous projects.</p>
                    },
                    classname: "stab1:!mb-16 stab1:text-center smob1:px-4 smob1:!mb-4 smob1:text-justify"
                },
                {
                    type: 'text',
                    content: {
                        header: 'Service Available',
                        paragraph: 'In addition to software development, we also offer a range of specialized services designed to support your business at every stage of growth. We tailored solutions to ensure that each aspect of your technology ecosystem is optimized and <b>aligned with your goals</b>.<br/><br/>Here\'s a look at some of the additional services we proudly offer:',
                    },
                    headerClass: "text-center smob1:text-center",
                    classname: "mb-4 my-8 stab1:!mb-12 stab1:text-center smob1:px-4 smob1:!mb-4 smob1:text-justify"
                },
                {
                    type: 'swiper',
                    content: {
                        data: [{
                            title:'Custom Software',
                            img:'/Assets/images/banner/software-dev.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'App Development',
                            img:'/Assets/images/banner/app-dev.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Web Design',
                            img:'/Assets/images/banner/web-design.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'System Integrations',
                            img:'/Assets/images/banner/integrated-system.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Network Infrastructure',
                            img:'/Assets/images/banner/network-infra.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Disaster Planning & Recovery',
                            img:'/Assets/images/banner/recovery.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Legacy Product Upgrade',
                            img:'/Assets/images/banner/legacy-upgrade.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        }]
                    },
                    classname: 'mb-24 smob1:!mb-8'
                },
                {
                    type: 'linkparagraph',
                    content: {
                        paragraph: <p>Click <Link style={{textDecoration:'underline', color:'#208bf2'}} to="/portfolio">here</Link> if you`re interested in learning more about our previous projects.</p>
                    },
                    classname: "stab1:!mb-16 stab1:text-center smob1:px-4 smob1:!mb-8 smob1:text-justify"
                }
            ],
            // questions: [
            //     {
            //         question: 'What is the design process for branding?',
            //         answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
            //     },
            //     {
            //         question: 'What is the design process for branding?',
            //         answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
            //     },
            //     {
            //         question: 'What is the design process for branding?',
            //         answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
            //     },
            //     {
            //         question: 'What is the design process for branding?',
            //         answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
            //     }
            // ],
	    }
    },
    {
        id: "sys-integration",
        title: "System Integration",
        shortTitle: "System Integration",
        img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
        icon: "",
        lottieIcon:{
            className: 'w-[90%] h-[90%] top-0 bottom-0 right-0 left-0 m-auto',
            path: integrateLottieIcon
        },
        video: Video,
        descr: "So everything runs together effortlessly, automatically.",
        serviceList: [
            {
                name: 'Legacy Product Upgrade',
			    link: 'legacy-upgrade',
            },
            {
                name: 'On Demand Support',
			    link: 'tech-support',
            },
            {
                name: 'Consultation',
			    link: 'consultation',
            },
        ],
        content: {
            title: 'Let\'s Build Your Next Big Move Together',
            banner: '/Assets/images/banner/sys-integration-banner.webp',
            images: ['/Assets/images/image-placeholder.png', '/Assets/images/image-placeholder.png'],
            body: [
                {
                    type: 'text',
                    content: {
                        header: 'Where Everything Runs Smoothly, Automatically',
                        paragraph: 'We approach system integration with a deep understanding of both the technical and strategic needs of your business. Our team ensures each system works in harmony, providing custom solutions that are scalable, secure, and designed with your future growth in mind.<br/><br/> With expertise across multiple platforms and technologies, we deliver seamless, end-to-end integration that eliminates bottlenecks and creates a smoother workflow across your entire organization.'
                    },
                    headerClass: "desk1:text-center tab1:text-center",
                    classname: "mb-24 stab1:text-center tab1:!mb-12 smob1:text-justify smob1:px-4"
                },
                {
                    type: 'imageAndPoint',
                    content: {
                        header: 'Benefits of Integrated System',
                        list: [
                            '<b>Enhanced Efficiency</b>: Eliminates repetitive tasks and reduces manual intervention',
                            '<b>Seamless Data Flow</b>: Data flows seamlessly between departments, reducing errors and creating a single source of truth',
                            '<b>Scalable Solutions</b>: Our integrations are designed to grow with you,',
                            '<b>Cost Savings</b>: Reduce operational costs and enhance resource allocation with automated workflow,'
                        ],
                        video: RainbowGradient,
                        image: '/Assets/images/banner/sysintegration_text.webp',
                        imagefit: "object-contain",
                        headerClass: "tab1:text-center",
                    },
                    
                    classname: "mb-28 stab1:mb-12 smob1:!mb-8"
                },
                {
                    type: 'text',
                    content: {
                        paragraph: 'Many companies face concerns about downtime, compatibility, and the potential for unexpected costs. Without the right partner, system integration can become complex, leaving gaps between your systems that result in inefficiencies and missed opportunities.<br/><br/>Our work is trusted by leading organizations that demand efficiency, reliability, and forward-thinking technology.'
                    },
                    classname: "my-8 stab1:mb-8 smob1:px-4 smob1:!mb-4 smob1:text-justify"
                },
                {
                    type: 'component',
                    component: <ClientMarquee/>,
                    classname: "clients-container w-full mb-24"
                },
            ],
	    }
    },
    {
        id: "web-design",
        title: "Web Design",
        shortTitle: "Web Design",
        img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
        icon: "",
        lottieIcon:{
            className: 'w-[90%] h-[90%] top-0 bottom-0 right-0 left-0 m-auto',
            path: webLottieIcon
        },
        video: Video,
        descr: "Designing impact and driving change start with the first impression.",
        serviceList: [
            {
                name: 'App Development',
			    link: 'custom-dev',
            },
            {
                name: 'Consultation',
			    link: 'consultation',
            },
            {
                name: 'Tech Support',
			    link: 'tech-support',
            }
        ],
        content: {
            title: 'First Impression Matters',
            banner: '/Assets/images/banner/web-design-banner.webp',
            images: ['/Assets/images/image-placeholder.png', '/Assets/images/image-placeholder.png'],
            body: [
                {
                    type: 'text',
                    content: {
                        header: 'First Impression Matters',
                        paragraph: 'Great web design goes beyond aesthetics—it\'s about creating a seamless user experience that engages visitors and drives action. Our web design services are tailored to help your business stand out online with modern, responsive designs that are both beautiful and functional,'
                    },
                    headerClass: "desk1:text-center smob1:text-center",
                    classname: "mb-20 text-center tab1:!mb-16 smob1:text-justify smob1:px-4"
                },
                {
                    type: 'swiper',
                    content: {
                        data: [{
                            title:'Business Profile',
                            img:'/Assets/images/banner/software-dev.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Mobile Apps',
                            img:'/Assets/images/banner/app-dev.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'E-Commerce Website',
                            img:'/Assets/images/banner/ecommerce.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Landing Page',
                            img:'/Assets/images/banner/web-design.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        }]
                    },
                    classname: 'mb-32 tab1:!mb-20'
                },
                {
                    type: 'text',
                    content: {
                        header: 'Our Approach',
                        paragraph: 'At MXI Solutions, we take a strategic approach to web design, blending creativity with technical expertise. We start by understanding your brand, your audience, and your goals. From there, we craft a user-centered design that not only looks great but also delivers a seamless experience across all devices.'
                    },
                    headerClass: "desk1:text-center smob1:text-center",
                    classname: "mb-20 text-center tab1:!mb-12 smob1:text-justify smob1:px-4"
                },
                {
                    type: 'imageAndPoint',
                    content: {
                        header: 'The Process',
                        headerClass: "text-center",
                        list: [
                            '<b>Discovery & Planning</b>:  We start by understanding your brand, target audience, and project goals.',
                            '<b>Design & Prototyping</b>:  We create wireframes and prototypes that lay out the structure',
                            '<b>Development & Testing</b>:  We brings the website to life with rigorous testing',
                            '<b>Deployment & Support</b>:  Once the website is finalized and approved, we handle the deployment process with ongoing support'
                        ],
                        video: Video,
                        image: '/Assets/images/banner/web-design-impression.webp',
                        imagefit: "object-contain"
                    },
                    headerClass: "text-center smob1:text-center",
                    classname:"mb-28 stab1:!mb-16 mob1:mb-10"
                },
                {
                    type: 'text',
                    content: {
                        paragraph: 'At MXI, we have experience across diverse industries, from financial services to retail. Our team\'s versatility and laser focus on delivering clients\' goals is something we\'re proud of.<br/><br/> Here is a list of clients we\'ve had the opportunity to work with.',
                    },
                    classname: "mb-8 smob1:mt-4 smob1:mb-8 smob1:px-4 smob1:text-justify"
                },{
                    type: 'component',
                    component: <ClientMarquee/>,
                    classname: "clients-container w-full mb-24 stab1:mb-16 mob1:mb-10 "
                },
                {
                    type: 'text',
                    content: {
                        paragraph: 'Your website is your digital storefront. Let\'s make sure it <b>stands out</b>.</br></br> <b>Contact us today</b> to see how we can transform your online presence with a custom, responsive website.',
                    },
                    classname: "mb-16 smob1:px-4 tab1:!mb-12 stab1:text-center smob1:text-justify"
                }
            ],
            // questions: [
            //     {
            //         question: 'What is the design process for branding?',
            //         answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
            //     },
            //     {
            //         question: 'What is the design process for branding?',
            //         answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
            //     },
            //     {
            //         question: 'What is the design process for branding?',
            //         answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
            //     },
            //     {
            //         question: 'What is the design process for branding?',
            //         answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
            //     }
            // ],
	    }
    },
    // {
    //     id: "graphic-design",
    //     title: "Graphic Design",
    //     shortTitle: "",
    //     img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
    //     icon: "",
    //     lottieIcon:{
    //         className: 'w-[90%] h-[90%] top-0 bottom-0 right-0 left-0 m-auto',
    //         path: artistLottieIcon
    //     },
    //     video: Video2,
    //     descr: "Deliver message in the most impactful way possible.",
    //     content: {
    //         title: 'Let’s Build Your Next Big Move Together',
    //         banner: '/Assets/images/image-placeholder.png',
    //         images: ['/Assets/images/image-placeholder.png', '/Assets/images/image-placeholder.png'],
    //         body: [
    //             {
    //                 type: 'text',
    //                 content: {
    //                     header: 'IT Solution And Business',
    //                     paragraph: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.'
    //                 }
    //             },
    //             {
    //                 type: 'imageAndPoint',
    //                 content: {
    //                     header: 'Service Features',
    //                     list: [
    //                         'We Provide Flexible IT Services',
    //                         'We Provide Flexible IT Services',
    //                         'We Provide Flexible IT Services',
    //                         'We Provide Flexible IT Services'
    //                     ],
    //                     image: '/Assets/images/image-placeholder.png'
    //                 }
    //             },
    //             {
    //                 type: 'text',
    //                 content: {
    //                     header: 'IT Solution And Business',
    //                     paragraph: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.'
    //                 }
    //             }
    //         ],
    //         questions: [
    //             {
    //                 question: 'What is the design process for branding?',
    //                 answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
    //             },
    //             {
    //                 question: 'What is the design process for branding?',
    //                 answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
    //             },
    //             {
    //                 question: 'What is the design process for branding?',
    //                 answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
    //             },
    //             {
    //                 question: 'What is the design process for branding?',
    //                 answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
    //             }
    //         ],
	//     }
    // },
    {
        id: "legacy-upgrade",
        title: "Legacy Upgrade",
        shortTitle: "Legacy Product",
        img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
        icon: "",
        lottieIcon:{
            className: 'w-[90%] h-[90%] top-0 bottom-0 right-0 left-0 m-auto',
            path: legacyLottieIcon
        },
        video: Video2,
        descr: "As business processes evolve, so should your software.",
        serviceList: [
            {
                name: 'Software Development',
			    link: 'custom-dev',
            },
            {
                name: 'Consultation',
			    link: 'consultation',
            },
            {
                name: 'Tech Support',
			    link: 'tech-support',
            }
        ],
        content: {
            title: 'Let\'s Build Your Next Big Move Together',
            banner: '/Assets/images/banner/legacy-upgrade-banner.webp',
            images: ['/Assets/images/image-placeholder.png', '/Assets/images/image-placeholder.png'],
            body: [
                {
                    type: 'text',
                    content: {
                        header: 'Technology Advances, So Must Your Systems.',
                        paragraph: 'If building a new system isn\'t what you need because most of the features you want are already in your existing system, but you\'re looking for a better, more user-friendly UI with current trends and technologies—or if you want to add new AI features like integrating ChatGPT, image-to-text or speech-to-text—we\'re here to help.<br/><br/>Our Legacy Product Upgrade Services are designed to breathe new life into your older software, ensuring it meets today\'s standards and supports your current business needs',
                    },
                    headerClass: "desk1:text-center smob1:text-center",
                    classname: "mb-20 text-center stab1:text-center tab1:!mb-10 smob1:text-justify smob1:px-4"
                },
                {
                    type: 'imageAndPoint',
                    content: {
                        header: 'Why the Upgrade',
                        headerClass: "text-center",
                        list: [
                            '<b>Decreased Efficiency</b>: Upgrading the system introduces modern technologies, automates repetitive tasks helping your team work more efficiently.',
                            '<b>Poor User Experience</b>: A refreshed UI/UX design and new features enhance usability, making the system more intuitive and enjoyable for users.',
                            '<b>Security Vulnerabilities</b>: Upgrading your system includes implementing the latest security features and protocols, safeguarding your data and protecting against new threats.',
                            '<b>High Maintenance</b>: Upgraded software reduces the need for constant maintenance by using newer, more reliable code and technologies.',
                        ],
                        video: YellowGradient,
                        image: '/Assets/images/banner/legacy_text.webp',
                        imagefit: "object-contain"
                    },
                    headerClass: "text-center smob1:text-center",
                    classname:"mb-24 tab1:!mb-16"
                },
                // {
                //     type: 'imageAndPoint',
                //     content: {
                //         header: 'The Process',
                //         list: [
                //             '<b>Assessment</b>: Gather requirements. Identifying critical pain points and opportunities for improvement.',
                //             '<b>Design & Strategy</b>:  This includes selecting modern technologies, defining new features, and creating a roadmap for implementation.',
                //             '<b>Development & Testing</b>:  Refactor old code, add new features, integrate modern components and testing. The goal is to enhance performance and scalability without disrupting existing workflows',
                //             '<b>Deployment & Support</b>:  We deploy the software to your environment with minimized downtime and provide ongoing support'
                //         ],
                //         image: '/Assets/images/image-placeholder.png'
                //     },
                //     classname: "mb-8"
                // },
                // {
                //     type: 'text',
                //     content: {
                //         paragraph: 'Upgrading a legacy product isn’t a task that just anyone can handle. It requires a specific set of skills, expertise, and strategic planning to ensure a smooth transition and a successful outcome:',
                //         dlist: [
                //             '<b>Client-Centered Approach</b>: Prioritizing the needs of the business and understanding goals of the upgrade is essential to enhance performance and user-experience of the system.',
                //             '<b>Problem-Solving Skills</b>: Legacy systems often have accumulated technical debt and undocumented features, making upgrades challenging.',
                //             '<b>Deep Understanding of Legacy Systems</b>: For making updates without breaking existing functionality.',    
                //             '<b>Experience with Data Migration</b>: To ensure data integrity, avoid data loss, and ensure smooth transitions.',
                //         ],
                //         dlist_position: 'top'
                //     },
                //     classname: "bg-gradient-to-r from-white to-white p-8 text-black border-2 border-gray-200 rounded-3xl",
                // },
                // {
                //     type: 'text',
                //     content: {
                //         paragraph: 'We take a strategic, step-by-step approach to upgrading legacy systems. We start by conducting a thorough assessment of your current software, identifying critical pain points and opportunities for improvement. Our team then creates a tailored plan that prioritizes data integrity, security, and seamless integration with your existing infrastructure.'
                //     },
                //     classname: ""
                // },
                {
                    type: 'text',
                    content: {
                        paragraph: 'At MXI Solutions, we take pride in our broad experience across various industries. Here are some of the companies we’ve had the pleasure of working with.'
                    },
                    classname: "text-center mb-8 tab1:mt-4 tab1:mb-4 smob1:px-4 smob1:text-justify"
                },
                {
                    type: 'component',
                    component: <ClientMarquee/>,
                    classname: "clients-container w-full mb-24 tab1:mb-16"
                },
                {
                    type: 'text',
                    content: {
                        paragraph: 'From financial services to retail, we\'ve helped businesses across various industries successfully modernize their legacy products. Our clients have seen improved performance, higher user satisfaction, and significant cost savings after upgrading their outdated systems.<br/><br/>Don\'t let an outdated product hold back your business growth.<br/><br/><b>Contact us today</b> for a Free Consultation,<br/><br/>and let\'s explore how upgrading your legacy software can set you up for future success.'
                    },
                    classname: "text-center mb-12 smob1:px-4 smob1:!mb-12 smob1:text-justify"
                }
            ],
            questions: [
                {
                    question: 'What is the process of upgrading legacy product ?',
                    answer: '<ul style="list-style:disc;"><li><b>Assessment</b>: Gather requirements. Identifying critical pain points and opportunities for improvement.</li><li><b>Design & Strategy</b>:  This includes selecting modern technologies, defining new features, and creating a roadmap for implementation.</li><li><b>Development & Testing</b>:  Refactor old code, add new features, integrate modern components and testing. The goal is to enhance performance and scalability without disrupting existing workflows</li><li><b>Deployment & Support</b>:  We deploy the software to your environment with minimized downtime and provide ongoing support</li></ul>'
                },
                {
                    question: 'How`s the payment plan ?',
                    answer: 'Like all our other services, we use a progressive payment plan where you only pay for the work that has been completed. However, a deposit may be required at the start of the project.'
                }
            ],
	    }
    },
    {
        id: "tech-support",
        title: "Technical Support",
        shortTitle: "Technical Support",
        img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
        icon: "",
        lottieIcon:{
            className: 'w-[90%] h-[90%] top-0 bottom-0 right-0 left-0 m-auto',
            path: supportLottieIcon
        },
        video: Video,
        descr: 'Evolving technology demands evolving support - we\'ve got you covered.',
        serviceList: [
            {
                name: 'Consultation',
			    link: 'consultation',
            },
            {
                name: 'Website Design',
			    link: 'web-design',
            },
            {
                name: 'App Development',
			    link: 'custom-dev',
            },
        ],
        content: {
            title: 'Let\'s Build Your Next Big Move Together',
            banner: '/Assets/images/banner/tech-support-banner.webp',
            images: ['/Assets/images/image-placeholder.png', '/Assets/images/image-placeholder.png'],
            body: [
                {
                    type: 'text',
                    content: {
                        header: 'Evolving Technology Demands Evolving Support',
                        paragraph: 'We offer proactive, responsive, and reliable support tailored to your unique needs, so you can focus on what matters most—growing your business.'
                    },
                    headerClass: "text-center smob1:text-center",
                    classname: "mb-20 text-center smob1:!mb-12 smob1:text-justify smob1:px-4"
                },
                {
                    type: 'imageAndPoint',
                    content: {
                        header: 'Who Need It?',
                        headerClass: "text-center",
                        list: [
                            'In today\'s digital age, technology is the backbone of any business. Even a minor glitch can disrupt your workflow, slow down productivity, and impact your business.<br/><br/>Without reliable technical support, you risk facing recurring issues, unexpected downtime, and frustrated users.',
                            
                        ],
                        video: YellowGradient,
                        image: '/Assets/images/banner/support_text.webp',
                        imagefit: "object-contain"
                    },
                    headerClass: "text-center smob1:text-center",
                    classname: "mb-24 text-center mx-auto stab1:mb-16 smob1:!mb-12"
                },
                {
                    type: 'text',
                    content: {
                        paragraph: 'Our Technical Support Services are designed to help you resolve issues quickly, minimize downtime, and keep your operations running seamlessly. It\'s not just about fixing problems—it\'s about preventing them before they happen and giving you peace of mind.'
                    },
                    classname: "mb-20 tab1:!mb-16 tab1:text-justify smob1:px-4",
                },
                {
                    type: 'text',
                    content: {
                        header: 'Services Provided',
                        paragraph: 'Here are the common types of technical support services we provided:',
                    },
                    headerClass: "tab1:text-center",
                    classname: "mb-16 smob1:px-4 tab1:!mb-8 tab1:text-center",
                },{
                    type: 'swiper',
                    content: {
                        data: [{
                            title:'Software Installations & Updates',
                            img:'/Assets/images/banner/software-dev.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'System Optimizations',
                            img:'/Assets/images/banner/app-dev.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Hardware & Network Support',
                            img:'/Assets/images/banner/ecommerce.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Cloud Support',
                            img:'/Assets/images/banner/web-design.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        },{
                            title:'Disaster & Recovery Support',
                            img:'/Assets/images/banner/web-design.webp',
                            swiperTextClass: 'bottom-0 top-0 my-auto text-3xl max-w-[70%] drop-shadow-xl text-center text-white text-shadow my-auto left-0 right-0 mx-auto w-fit h-fit'
                        }]
                    },
                    classname: 'mb-32 smob1:!mb-12'
                },
                // {
                //     type: 'text',
                //     content: {
                //         header: 'Services Provided',
                //         paragraph: 'Here are the common types of technical support services we provided:',
                //         dlist: [
                //             '<b>Software Installations & Updates</b>',
                //             '<b>System Optimizations</b>',
                //             '<b>Hardware & Network Support</b>.',    
                //             '<b>Cloud Support</b>',
                //             '<b>Disaster & Recovery Support</b>'
                //         ],
                //         dlist_position: 'center'
                //     },
                //     classname: "mb-16",
                // },
                {
                    type: 'text',
                    content: {
                        header: 'Our Work Speak for Itself',
                        paragraph: 'Our clients range from small businesses to large enterprises, all of whom trust MXI Solutions for dependable technical support.<br/><br/>Here are some of the companies we\'ve had the pleasure of working with.'
                    },
                    headerClass: "tab1:text-center",
                    classname: "mb-8 stab1:text-center smob1:mt-4 smob1:mb-4 smob1:px-4 smob1:text-justify"
                },
                {
                    type: 'component',
                    component: <ClientMarquee/>,
                    classname: "clients-container w-full mb-16 smob1:mb-8"
                },
                {
                    type: 'text',
                    content: {
                        paragraph: 'At MXI Solutions, we offer more than just basic troubleshooting. <br/><br/>Our team of skilled technicians provides end-to-end support, including issue diagnosis, timely resolution, and proactive monitoring to prevent future problems.<br/><br/>Contact us now to learn more :)'
                    }, 
                    classname: "mb-12 stab1:text-center smob1:px-4 tab1:!mb-10 smob1:text-justify"
                }
            ]
	    }
    },
];

export default posts;